@use "../variables.scss" as *;

.hero-section {
  height: "769px";
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 20px;
}

.hero-section-exporters {
  @extend .hero-section;
  background: url(../../svg/homepage/hero-img-exporters.webp);
  background-size: cover;
  background-repeat: no-repeat;
}

.hero-section-backers {
  @extend .hero-section;
  background: url(../../svg/homepage/hero-img-backers.webp);
  background-size: cover;
  background-repeat: no-repeat;
}

.diversify-container {
  padding: 100px 0px;

  .diversify-outcome {
    padding: 80px 0px;
  }
}
.backer-journey-container {
  padding-top: 100px;
  padding-bottom: 120px;
  z-index: 1;
  position: relative;
  .backer-journey-oval {
    position: absolute;
    overflow-x: hidden;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.journey-container {
  padding-top: 50px;
  margin: 0 auto;
  z-index: 1;
  position: relative;
  // .journey-info {
  //   margin-top: 110px;
  //   .exporter-journey-spacing {
  //     margin-top: 80px;
  //   }
  //   .exporter-journey-spacing2 {
  //     margin-top: 75px;
  //   }
  //   .backer-journey-spacing {
  //     margin-top: 110px;
  //   }
  //   .backer-journey-spacing2 {
  //     margin-top: 85px;
  //   }
  // }
  .journey-oval {
    position: absolute;
    overflow-x: hidden;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  // .arrow {
  //   position: absolute;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
  // .start-arrow {
  //   @extend .arrow;
  //   top: 150px;
  // }
  // .other-arrow {
  //   @extend .arrow;
  //   top: 450px;
  // }
  // .other-arrow2 {
  //   @extend .arrow;
  //   top: 750px;
  // }
  // .other-arrow3 {
  //   @extend .arrow;
  //   top: 1020px;
  // }
  // .other-arrow4 {
  //   @extend .arrow;
  //   bottom: 600px;
  // }
  // .other-arrow5 {
  //   @extend .arrow;
  //   bottom: 290px;
  // }
  // .other-arrow6 {
  //   @extend .arrow;
  //   bottom: -120px;
  // }
}

.start-journey {
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.journey-ends {
  // position: relative;
  // margin-top: -50px;
  padding: 85px 0px;
  z-index: 0;
}

.start-journey-bg {
  @extend .start-journey;
  background: linear-gradient(rgba(3, 17, 4, 0.9), rgba(3, 32, 5, 0.9)),
    url(../../svg/homepage/start-journey-bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.backer-trade-bg {
  @extend .start-journey;
  background: linear-gradient(rgba(3, 17, 4, 0.9), rgba(3, 32, 5, 0.9)),
    url(../../svg/homepage/join-waitlist-bg.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.backer-tractor-bg {
  @extend .start-journey;
  background: linear-gradient(rgba(3, 17, 4, 0.9), rgba(3, 32, 5, 0.9)),
    url(../../svg/homepage/tractor-trailer.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.content {
  padding: 100px 80px;
  .content-container {
    max-width: "1131px";
    margin: 0 auto;
    padding: 0 75px;

    .content-container-sections {
      padding: 100px 0px;
      gap: 50px;

      .section-borders {
        border-radius: 16px;
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 44px 66px;

        .animated-content {
          position: absolute;
          z-index: 2;
          bottom: 65px;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 60%;
        }
      }
    }
  }
}

.navbar-scrolled {
  background: rgba(252, 252, 253, 0.95);
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 16px;
  margin: 20px auto;
  transition: background-color 0.3s ease;
  z-index: 99;
}

@media (max-width: 768px) {
  .content {
    padding: 50px 20px;
    .content-container {
      width: 100%;
      padding: 0 10px;
      .content-container-sections {
        padding: 20px 0px;
      }
    }
  }

  .journey-ends {
    padding: 20px 0px;
  }

  .diversify-container {
    padding: 50px 0px;

    .diversify-outcome {
      padding: 20px 0px;
    }
  }
}

.underline_content {
  position: relative;
  display: inline-block;
}

.underline_svg {
  position: absolute;
  bottom: 0;
  transform: translateY(6px);
  z-index: 999;
}

.img_underline_svg {
  position: absolute;
  bottom: 8%;
  transform: translateX(-50px);
  z-index: 999;
}

.paper_plane_wrapper {
  background: url("../../images/paper_plane_bg.png");
  width: 100%;
  // transform: translateY(-12px);
  height: 260px;
  background-size: cover;
}

.first_fund_card {
  position: relative;
  z-index: 3;
  background-color: white;
}

.second_fund_card {
  position: absolute;
  background-color: white;
  top: 0px;
  left: 50%;
  transform: translate(-50%, 25px) scale(0.9);
  z-index: 2;
  opacity: 0.8;
}

.third_fund_card {
  position: absolute;
  background-color: white;
  top: 0px;
  left: 50%;
  transform: translate(-50%, 50px) scale(0.8);
  z-index: 1;
  opacity: 0.5;
}

.funding_goal_card:hover {
  .fund_card:first-of-type {
    transform: translateY(8px) scale(1.05);
  }
  .fund_card:nth-of-type(2) {
    transform: translate(-50%, 8px) scale(0.9);
    opacity: 0;
  }
  .fund_card:nth-of-type(3) {
    transform: translate(-50%, 8px) scale(0.8);
    opacity: 0;
  }
}

.fund_card_group {
  width: 80%;
  height: 90px;
  margin: 60px auto 6px;
}

.fund_card {
  width: 100%;
  height: 100%;
  transition: all 0.3s linear;
  box-shadow: 0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814;
  background-color: white;
}

.campaign_card_group {
  width: 80%;
  height: 100px;
  margin: 60px auto 20px;
}
.campaign_card {
  width: 100%;
  height: 100%;
  transition: all 0.5s linear;
  box-shadow: 0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814;
  background-color: white;
}

.campaign_card {
  &:first-of-type {
    position: absolute;
    transform: translate(0%, -45px) scale(0.83, 0.7);
    z-index: 1;
    opacity: 0.5;
  }

  &:nth-of-type(2) {
    position: absolute;
    transform: translate(0%, -27px) scale(0.93, 0.8);
    z-index: 2;
    opacity: 0.9;
  }

  &:nth-of-type(3) {
    position: absolute;
    transform: translate(0%, 0px) scale(1, 1);
    z-index: 3;
    opacity: 1;
  }

  &:nth-of-type(4) {
    position: absolute;
    transform: translate(0%, 27px) scale(0.93, 0.8);
    z-index: 2;
    opacity: 0.8;
  }

  &:nth-of-type(5) {
    position: absolute;
    transform: translate(0%, 45px) scale(0.83, 0.7);
    z-index: 1;
    opacity: 0.4;
  }
}

.campaign_approval_card:hover {
  .campaign_card {
    &:first-of-type {
      position: absolute;
      transform: translate(0%, 45px) scale(0.83, 0.7);
      z-index: 1;
      opacity: 0.4;
    }
    &:nth-of-type(2) {
      position: absolute;
      transform: translate(0%, -45px) scale(0.83, 0.7);
      z-index: 1;
      opacity: 0.5;
    }
    &:nth-of-type(3) {
      position: absolute;
      transform: translate(0%, -27px) scale(0.93, 0.8);
      z-index: 2;
      opacity: 0.9;
    }
    &:nth-of-type(4) {
      position: absolute;
      transform: translate(0%, 0px) scale(1, 1);
      z-index: 3;
      opacity: 1;
    }
    &:nth-of-type(5) {
      position: absolute;
      transform: translate(0%, 27px) scale(0.93, 0.8);
      z-index: 2;
      opacity: 0.8;
    }
  }

  // .main_campaign_card_hovered {
  //   animation: align_main_campaign_card 0.5s ease-in-out forwards;
  // }
}

.campaign_info_box {
  &:hover {
    .campaign_info_card:first-of-type {
      z-index: 2;
      animation: expand_info_card 0.3s cubic-bezier(0.47, 1.64, 0.41, 0.8) forwards;
    }
    .campaign_info_card:nth-of-type(2) {
      z-index: 2;
      animation: expand_info_card 0.2s 0.2s cubic-bezier(0.47, 1.64, 0.41, 0.8) forwards;
    }
    .campaign_info_card:nth-of-type(3) {
      z-index: 2;
      animation: expand_info_card 0.2s 0.4s cubic-bezier(0.47, 1.64, 0.41, 0.8) forwards;
    }
    .campaign_info_card:nth-of-type(4) {
      z-index: 2;
      animation: expand_info_card 0.2s 0.6s cubic-bezier(0.47, 1.64, 0.41, 0.8) forwards;
    }
  }
}

.campaign_info_group {
  margin: 30px 0 20px;
}
.campaign_info_card {
  background-color: white;
}

@keyframes align_campaign_cards {
  100% {
    transform: translate(-50%, 8px) scale(0.9);
    opacity: 0;
  }
}

@keyframes align_main_campaign_card {
  100% {
    transform: translateY(8px) scale(1.05);
  }
}

@keyframes expand_info_card {
  100% {
    transform: scale(1.1);
  }
}

@media (max-width: 768px) {
  .underline_svg {
    transform: translateY(15px);
  }
}

.animated-path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 10s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.line {
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  overflow: visible;
}

.stop {
  fill: dimgrey;
  stroke-width: 0;
  visibility: hidden;
}

.end {
  visibility: hidden;
  width: 375px !important;
}
