.z-index-1 {
  z-index: 1 !important;
}
@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-0 {
    width: 0% !important;
  }

  .w-md-auto {
    width: auto !important;
  }
}
