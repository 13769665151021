.create-profile {
  position: relative;
  .create-profile-header {
    // position: sticky;
    // top: 80px;
    // z-index: 1;
    // width: 100%;

    position: fixed;
    top: 80px;
    z-index: 1;
    width: calc(100% - 240px);
    right: 0;
    padding: 25px 22px;
    background-color: var(--grey-50);
  }
  .create-profile-container {
    padding: 46px 0px 20px;
    position: relative;
    .create-profile-sidebar {
      width: 270px;
      // height: 100%;
      position: sticky;
      top: 169px;
      left: 0px;
      padding-top: 44px;
      overflow-y: auto;
      border-right: 1px solid var(--grey-100);
    }
  }
}

.user-profile-page {
  padding-bottom: 50px;
  .profile-page-container {
    max-width: 930px;

    .profile-avatar {
      height: 90px;
      width: 90px;
      position: relative;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
      }
      .avatar-upload-btn {
        width: 34px;
        height: 34px;
        border: 1px solid var(--white);
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .create-profile-container {
    padding-top: 0px;
  }
  .create-profile-sidebar {
    position: absolute !important;
    width: 100vw !important;
    top: 0 !important;
    padding: 0 !important;
  }
}
