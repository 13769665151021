@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css");

@import "./assets/css/variables.scss";
@import "./assets/css/app.scss";
@import "./assets/css/loginAuth/index.scss";
@import "./assets/css/dashboard/dashboard.scss";
@import "./assets/css/dashboard/profile.scss";

* {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: "Inter", sans-serif;
  // font-family: 'Montserrat', sans-serif;
}

html {
  // font-size: 62.5%;
  // scroll-behavior: smooth;
  font-size: 14px;
}

body {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #0E0E28;
}

.min-vh-100 {
  min-height: 100vh;
}

.container {
  max-width: 1400px !important;
  width: 95%;
  margin: 0 auto !important;
  padding: 0 !important;
}

.chakra-modal__content-container {
  align-items: center !important;
  justify-content: center !important;
  padding: 0 10px;
  // overflow: hidden !important;
}

.fs-13 {
  font-size: 13px !important;
}

ul {
  list-style-type: none;
  margin: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem !important;
  // color: var(--grey-900);
}

.touchable {
  cursor: pointer !important;
}

.no-click {
  pointer-events: none !important;
}

p {
  // color: var(--grey-600);
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
}

input {
  font-weight: 300;
}

// select {
// appearance: none !important;
// -webkit-appearance: none !important;
// -moz-appearance: none !important;
// background: transparent;
// background-image: url("../src/assets/svg/chevron-down.svg");
// background-repeat: no-repeat;
// background-position-x: 98%;
// background-position-y: 50%;
// }

select:disabled {
  background: var(--grey-50);
}

input,
select {
  font-size: 14px;
  color: var(--grey-700);
}

input,
select,
textarea {
  border: 1px solid var(--grey-300) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-weight: 300 !important;
  resize: none !important;
  padding: 10px 14px;
  position: relative;
  background-color: var(--white);
  // padding-top: 10px !important;
  // padding-bottom: 10px !important;
}

.chakra-select__wrapper {
  height: 100%;
}

.chakra-step__indicator {
  height: 40px;
  width: 40px;
  z-index: 4;

  svg {
    height: 100%;
    width: 100%;
  }
}
.chakra-step__separator[data-orientation="vertical"] {
  left: calc(40px / 2 - 1px);
  background: var(--primary-50) !important;
}

@media (max-width: 768px) {
  .chakra-step__separator[data-orientation="horizontal"] {
    z-index: 0 !important;
    top: 20px !important;
    position: absolute;
    left: 37px;
    width: 100% !important;
    height: 1px !important;
    background: var(--primary-50) !important;
  }
}

.ql-toolbar.ql-snow {
  position: absolute;
  bottom: -52px;
  border: none !important;
}

.ql-container.ql-snow {
  border: none !important;
}

object img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  border: 1px solid var(--grey-300);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

label {
  font-weight: 500;
  color: var(--grey-700);
  font-size: 14px;
}

#react-select-3-input {
  border: 0 !important;
  box-shadow: none !important;
}

.primary-multi-select {
  > div:first-of-type {
    border: 1px solid var(--grey-300);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
  div:not(#react-select-3-placeholder):not(#react-select-3-listbox) {
    // height: 100%;
  }
  #react-select-5-listbox {
    height: 400px;
  }
  // #react-select-3-placeholder div {
  //   height: auto !important;
  // }
  input {
    border: none !important;
    padding-top: calc(1rem - 3.2px) !important;
    padding-bottom: calc(1rem - 3.2px) !important;
    height: 100%;
  }
}

button {
  font-size: 14px !important;
  font-weight: 600 !important;
  border: none;
  outline: none;
  overflow: hidden;
  border-width: 2px;
  border-style: solid;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
}

button a {
  font-size: 14px;
  font-weight: 600 !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

a.primary-btn,
a.secondary-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  font-weight: 600;
}

//==== UI elements ====//

.custom-alert-box {
  display: grid;
  grid-template-columns: 0fr 16fr;
  gap: 10px;
  align-items: center;
  padding: 14px;
  border-radius: 8px;
  max-width: 1070px;
  .icon-size {
    width: 22px;
    height: 19px;
  }
  h2 {
    font-size: 14px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
  }
  p {
    line-height: 19px;
    font-weight: 400;
  }
}

.chakra-checkbox__control {
  background-color: var(--white) !important;
  border-width: 1.5px !important;
  border-color: var(--grey-300) !important;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  -o-border-radius: 4px !important;
}

.chakra-checkbox__control[aria-checked="true"],
.chakra-checkbox__control[data-checked] {
  background-color: var(--primary-50) !important;
  border-color: var(--primary-600) !important;
}

.chakra-checkbox__control:focus,
.chakra-checkbox__control:focus {
  background-color: var(--primary-50) !important;
  border-color: var(--primary-600) !important;
  box-shadow: 0px 0px 0px 4px rgba(244, 235, 255, 1) !important;
}

.chakra-checkbox__control[aria-disabled="true"],
.chakra-checkbox__control[data-disabled] {
  background-color: var(--grey-100) !important;
  border-color: var(--grey-200) !important;

  .css-fsye1 {
    color: var(--grey-200);
  }
}

.dropzone {
  background-color: var(--primary-25) !important;

  h5 {
    color: var(--grey-900);
  }
}

//Primary input
.primary-input,
.primary-select {
  border: 1px solid var(--grey-300) !important;
  height: 100% !important;
  padding: 10px 14px;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.primary-input:disabled,
.primary-select:disabled {
  background: var(--grey-50) !important;
  color: var(--grey-500) !important;
  border-color: var(--grey-300) !important;
  opacity: unset !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: not-allowed;
}

.primary-input:focus,
.primary-select:focus {
  border-color: var(--primary-600) !important;
  box-shadow: 0px 0px 0px 4px rgba(244, 235, 255, 1), 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}

.css-lcw54a[aria-invalid="true"],
.css-lcw54a[data-invalid],
.css-1aoy5ht[aria-invalid="true"],
.css-1aoy5ht[data-invalid],
.primary-input[aria-invalid="true"],
.primary-input[data-invalid],
.primary-select[aria-invalid="true"],
.primary-select[data-invalid],
.PhoneInput .PhoneInputInput[aria-invalid="true"],
.PhoneInput .PhoneInputInput[data-invalid],
.PhoneInput .PhoneInputInput:invalid {
  border-color: var(--error-300) !important;
}

.css-lcw54a[aria-invalid="true"]:focus,
.css-lcw54a[data-invalid]:focus,
.css-1aoy5ht[aria-invalid="true"]:focus,
.css-1aoy5ht[data-invalid]:focus,
.primary-input[aria-invalid="true"]:focus,
.primary-input[data-invalid]:focus,
.primary-select[aria-invalid="true"]:focus,
.primary-select[data-invalid]:focus {
  border-color: var(--error-300) !important;
  box-shadow: 0px 0px 0px 4px rgba(254, 228, 226, 1) !important;
}

.css-42b2qy {
  height: 100% !important;
}

// .primary-input:invalid {
//   border-color: var(--error-300) !important;
//   box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
// }

.primary-input-addon {
  height: 100% !important;
  background-color: transparent !important;
  border: 1px solid var(--grey-300) !important;
  color: var(--grey-500) !important;
  font-size: 15px !important;
  font-weight: 400 !important;

  select {
    border: none !important;
    width: 100%;
    height: 100%;
    background-position-x: 99%;
    background-position-y: 50%;
    padding: 10px 3px !important;
  }
}

.primary-input-component {
  position: absolute !important;
  top: 50% !important;
  display: inline-block;
  // left: 9px !important;
  // height: 100% !important;
  margin: 0 4px;
  transform: translateY(-50%) !important;
  -webkit-transform: translateY(-50%) !important;
  -moz-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  -o-transform: translateY(-50%) !important;

  select {
    border: none !important;
    width: 60px;
    height: 100%;
    background-color: transparent;
    background-position-x: 99%;
    background-position-y: 50%;
    padding: 10px 3px !important;
  }

  svg,
  img {
    height: auto;
    width: 24px;
  }
}

.input-error-icon {
  position: absolute !important;
  top: 50% !important;
  margin: 0 4px;
  transform: translateY(-50%) !important;

  svg,
  img {
    height: 15px;
    width: 15px;
  }
}

.password-input-icon {
  padding: 0 !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 9px;
}

.disabled-input {
  background: var(--grey-50) !important;
  color: var(--grey-500) !important;
  cursor: not-allowed;
}

.PhoneInput {
  border: none !important;
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;

  .PhoneInputCountry {
    height: 100% !important;
    background-color: transparent !important;
    border: 1px solid var(--grey-300) !important;
    border-right: none !important;
    color: var(--grey-500) !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    margin: 0 !important;
    padding: 0 16px;
    border-radius: 8px !important;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  select {
    border: none !important;
    width: 100%;
    height: 100%;
    background-position-x: 99%;
    background-position-y: 50%;
    padding: 10px 12px 10px 6px !important;
  }
  .PhoneInputInput {
    border: 1px solid var(--grey-300) !important;
    height: 100% !important;
    padding: 10px 14px;
    border-radius: 8px !important;
    -webkit-border-radius: 8px !important;
    -moz-border-radius: 8px !important;
    -ms-border-radius: 8px !important;
    -o-border-radius: 8px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
//Button sizes
.btn-lg {
  width: auto;
  // height: 48px !important;
  height: unset !important;
  padding: 12px 20px !important;
}

.btn-md {
  width: auto;
  // height: 40px !important;
  height: unset !important;
  padding: 10px 16px !important;
}

.btn-sm {
  width: auto;
  // height: 36px !important;
  height: unset !important;
  padding: 8px 14px !important;
}

.btn-xs {
  width: auto;
  // height: 30px !important;
  height: unset !important;
  padding: 5px 14px !important;
}

//Primary button
.primary-btn {
  background: var(--primary-600) !important;
  color: var(--white) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-width: 1px;
  border-style: solid;
  border-color: var(--primary-600) !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.btn-outline-primary {
  background: transparent !important;
  border: var(--primary-600) 2px solid !important;
  color: var(--primary-600) !important;
}

.primary-btn:hover:not(.rounded-pill) {
  background: var(--primary-800) !important;
  border-color: var(--primary-800) !important;
  color: var(--white) !important;
}

.primary-btn:focus:not(.rounded-pill) {
  background: var(--primary-600) !important;
  border-color: var(--primary-600) !important;
  color: var(--white) !important;
  box-shadow: 0px 0px 0px 4px rgba(244, 235, 255, 1), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.primary-btn:disabled {
  background: var(--primary-100) !important;
  color: var(--white) !important;
  border-color: var(--primary-100) !important;
  opacity: unset !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: default !important;
  // opacity: 0.4 !important;
}

.primary-btn:disabled:hover {
  color: var(--white) !important;
  background: var(--primary-100) !important;
  border-color: var(--primary-100) !important;
  // opacity: 0.4 !important;
}

.primary-btn a {
  color: var(--white) !important;
  text-decoration: none !important;
}

//Secondary button
.secondary-btn {
  background: var(--primary-50) !important;
  color: var(--primary-800) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-width: 1px;
  border-style: solid;
  border-color: var(--primary-50) !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.btn-outline-secondary {
  background: transparent !important;
  border: var(--primary-50) 2px solid !important;
  color: var(--white) !important;
  height: 41px !important;
  // padding: 16px 5px !important;
  border-radius: 8px !important;
  // -moz-border-radius: 8px !important;
  // -ms-border-radius: 8px !important;
  // -o-border-radius: 8px !important;
}

.btn-outline-secondary-scrolled {
  background: transparent !important;
  border: var(--primary-600) 2px solid !important;
  color: var(--black) !important;
  height: 41px !important;
  // padding: 16px 5px !important;
  border-radius: 8px !important;
  // -moz-border-radius: 8px !important;
  // -ms-border-radius: 8px !important;
  // -o-border-radius: 8px !important;
}

.secondary-btn:hover {
  background: var(--primary-100) !important;
  border-color: var(--primary-100) !important;
  color: var(--primary-800) !important;
}

.secondary-btn:focus:not(.rounded-pill) {
  background: var(--primary-50) !important;
  border-color: var(--primary-50) !important;
  color: var(--primary-800) !important;
  box-shadow: 0px 0px 0px 4px rgba(244, 235, 255, 1), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.secondary-btn:disabled {
  background: var(--primary-25) !important;
  color: var(--primary-300) !important;
  border-color: var(--primary-25) !important;
  opacity: unset !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: default;
}
.secondary-btn:disabled:hover {
  background: var(--primary-25) !important;
  color: var(--primary-300) !important;
  border-color: var(--primary-25) !important;
  cursor: default;
}

.secondary-btn a {
  color: var(--primary-800) !important;
  text-decoration: none !important;
}

//Primary error button
.primary-error-btn {
  background: var(--error-600) !important;
  color: var(--white) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-width: 1px;
  border-style: solid;
  border-color: var(--error-600) !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.primary-error-btn:hover {
  background: var(--warning-700) !important;
  border-color: var(--warning-700) !important;
  color: var(--white) !important;
}

.primary-error-btn:focus {
  background: var(--error-600) !important;
  border-color: var(--error-600) !important;
  color: var(--white) !important;
  box-shadow: 0px 0px 0px 4px rgba(244, 235, 255, 1), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.primary-error-btn:disabled {
  background: var(--error-200) !important;
  border-color: var(--error-200) !important;
  color: var(--white) !important;
  opacity: unset !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: default;
}
.primary-error-btn:disabled:hover {
  background: var(--error-200) !important;
  border-color: var(--error-200) !important;
  color: var(--white) !important;
  cursor: default;
}

.primary-error-btn a {
  color: var(--white) !important;
  text-decoration: none !important;
}

//Secondary error button
.secondary-error-btn {
  background: var(--error-50) !important;
  color: var(--warning-700) !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-width: 1px;
  border-style: solid;
  border-color: var(--error-50) !important;
  border-radius: 8px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.secondary-error-btn:hover {
  background: var(--error-100) !important;
  border-color: var(--error-100) !important;
  color: var(--warning-700) !important;
}

.secondary-error-btn:focus {
  background: var(--error-50) !important;
  border-color: var(--error-50) !important;
  color: var(--warning-700) !important;
  box-shadow: 0px 0px 0px 4px rgba(254, 228, 226, 1), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.secondary-error-btn:disabled {
  background: var(--error-25) !important;
  border-color: var(--error-25) !important;
  color: var(--error-300) !important;
  opacity: unset !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: default;
}
.secondary-error-btn:disabled:hover {
  background: var(--error-25) !important;
  border-color: var(--error-25) !important;
  color: var(--error-300) !important;
  cursor: default;
}

.secondary-error-btn a {
  color: var(--warning-700) !important;
  text-decoration: none !important;
}

.primary-color {
  color: var(--primary-600) !important;
}
.primary-bg {
  background-color: var(--primary-600) !important;
}
.border-w-1 {
  border-width: 1px !important;
}
.text-danger {
  color: var(--error-600) !important;
}

.border-default {
  border: 1px solid var(--grey-300) !important;
}
.object-fit-cover {
  object-fit: cover;
}

.table-responsive {
  background-image: -webkit-gradient(
      linear,
      0% 50%,
      100% 50%,
      color-stop(0%, #ffffff),
      color-stop(100%, rgba(255, 255, 255, 0))
    ),
    -webkit-gradient(linear, 100% 50%, 0% 50%, color-stop(0%, #ffffff), color-stop(100%, rgba(255, 255, 255, 0))),
    -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0%, #c3c3c573), color-stop(100%, rgba(195, 195, 197, 0))),
    -webkit-gradient(linear, 100% 50%, 0% 50%, color-stop(0%, #c3c3c573), color-stop(100%, rgba(195, 195, 197, 0)));
  background-image: -webkit-linear-gradient(left, #ffffff, rgba(255, 255, 255, 0)),
    -webkit-linear-gradient(right, #ffffff, rgba(255, 255, 255, 0)),
    -webkit-linear-gradient(left, #c3c3c573, rgba(195, 195, 197, 0)),
    -webkit-linear-gradient(right, #c3c3c573, rgba(195, 195, 197, 0));
  background-position: 0 0, 100% 0, 0 0, 100% 0;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 4em 100%, 4em 100%, 1em 100%, 1em 100%;
  background-attachment: local, local, scroll, scroll;
}

.chakra-portal {
  * {
    &::-webkit-scrollbar {
      width: 10px;
      background: transparent;
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 50px;
    }
  }
}

.pagination-btn {
  height: 32px;
  width: 32px;
  border: 1px solid var(--grey-300);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;

  &:disabled {
    background-color: var(--grey-300);
    opacity: 0.5;
    cursor: default;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">") !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.ps-6 {
  padding-left: 4rem !important;
}
.pe-6 {
  padding-right: 4rem !important;
}

.h-44 {
  height: 44px !important;
}

.rounded-start-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

/* This will affect the scrollbar globally */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-800) !important;
}

//==== Responsive width classes ====//
.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

// .navbar {
//   transition: background-color 0.3s ease;
// }

//==== End of Responsive width classes ====//

@media (max-width: 500px) {
  h1 {
    font-size: 20px !important;
    line-height: 32px !important;
  }
}

//==== Animations ====//

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes dropDown {
  0% {
    transform: translateY(0);
    opacity: 0;
  }

  50% {
    transform: translateY(4px);
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }

  70% {
    transform: rotateX(20deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

@keyframes fadeDown {
  0% {
    top: 0;
    opacity: 0;
  }

  50% {
    top: 100%;
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes drop {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes fadeLeft {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
  }

  50% {
    opacity: 1;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
  }
}

//==== End of Animations ====//

table.spaced-cells {
  th,
  td {
    padding: 0.8rem 1rem;
  }
}
