:root {
  // --heading-black: #222222;
  // --primary-text: #626262;
  // --secondary-text: #515151;
  // --auth-text: #b1b1b1;
  // --primary-green: #2f8132;
  // --secondary-green: #e3f9e5;
  // --light-green: #f2fff3;
  // --warning-bg: #fffcf5;
  // --warning-text: #f79009;
  // --error-bg: #fef3f2;
  // --error-text: #d92d20;
  // --light-grey: #e1e1e1;
  // --drag-drop-bg: #4fc84f;
  // --off-white: #f7f9fb;
  // --primary-btn-disabled: #8cc7f7;
  // --primary-btn-hover: #0072cf;
  // --secondary-btn-hover: #0084e21a;
  // --input-border-color: #b1b1b1;
  // --footer-black: #34373a;
  // --footer-hr: #a5a5a5;
  // --secondary-50: #f4f9fd;
  // --gold: #ffd700;
  // --success: #00a788;

  --black: #000;
  --white: #fff;

  --primary-25: #f2fff3;
  --primary-50: #e3f9e5;
  --primary-100: #a3d9a5;
  --primary-200: #7bc47f;
  --primary-300: #4fc84f;
  --primary-400: #57ae5b;
  --primary-500: #3f9142;
  --primary-600: #2f8132;
  --primary-700: #207227;
  --primary-800: #0e5814;
  --primary-900: #05400a;

  --grey-25: #fcfcfd;
  --grey-50: #f7f7f7;
  --grey-100: #e1e1e1;
  --grey-200: #cfcfcf;
  --grey-300: #b1b1b1;
  --grey-400: #9e9e9e;
  --grey-500: #7e7e7e;
  --grey-600: #626262;
  --grey-700: #515151;
  --grey-800: #3b3b3b;
  --grey-900: #222222;

  --error-25: #fffbfa;
  --error-50: #fef3f2;
  --error-100: #fee4e2;
  --error-200: #fecdca;
  --error-300: #fda29b;
  --error-400: #f97066;
  --error-500: #f04438;
  --error-600: #d92d20;
  --error-700: #b42318;
  --error-800: #912018;
  --error-900: #7a271a;

  --warning-25: #fffcf5;
  --warning-50: #fffaeb;
  --warning-100: #fef0c7;
  --warning-200: #fedf89;
  --warning-300: #fec84b;
  --warning-400: #fdb022;
  --warning-500: #f79009;
  --warning-600: #dc6803;
  --warning-700: #b54708;
  --warning-800: #93370d;
  --warning-900: #7a2e0e;

  --success-25: #f6fef9;
  --success-50: #ecfdf3;
  --success-100: #d1fadf;
  --success-200: #a6f4c5;
  --success-300: #6ce9a6;
  --success-400: #32d583;
  --success-500: #12b76a;
  --success-600: #039855;
  --success-700: #027a48;
  --success-800: #05603a;
  --success-900: #054f31;

  --info-25: #f2f9ff;
  --info-50: #e3f0ff;
  --info-100: #a3d0ff;
  --info-200: #7bbeff;
  --info-300: #4fa3ff;
  --info-400: #5791ff;
  --info-500: #3f6eff;
  --info-600: #2f5cff;
  --info-700: #2049ff;
  --info-800: #0e36ff;
  --info-900: #0528ff;
}

// Dynamic Colors
@for $i from 25 through 900 {
  .bg-primary-#{$i} {
    background-color: var(--primary-#{$i});
  }
  .hover-bg-primary-#{$i}:hover {
    background-color: var(--primary-#{$i});
  }

  .bg-grey-#{$i} {
    background-color: var(--grey-#{$i});
  }
  .hover-bg-grey-#{$i}:hover {
    background-color: var(--grey-#{$i});
  }

  .bg-error-#{$i} {
    background-color: var(--error-#{$i});
  }
  .hover-bg-error-#{$i}:hover {
    background-color: var(--error-#{$i});
  }

  .bg-warning-#{$i} {
    background-color: var(--warning-#{$i});
  }
  .hover-bg-warning-#{$i}:hover {
    background-color: var(--warning-#{$i});
  }

  .bg-success-#{$i} {
    background-color: var(--success-#{$i});
  }
  .hover-bg-success-#{$i}:hover {
    background-color: var(--success-#{$i});
  }

  .bg-info-#{$i} {
    background-color: var(--info-#{$i});
  }
  .hover-bg-info-#{$i}:hover {
    background-color: var(--info-#{$i});
  }

  .text-primary-#{$i} {
    color: var(--primary-#{$i});
  }

  .text-grey-#{$i} {
    color: var(--grey-#{$i});
  }

  .text-error-#{$i} {
    color: var(--error-#{$i});
  }

  .text-warning-#{$i} {
    color: var(--warning-#{$i});
  }

  .text-success-#{$i} {
    color: var(--success-#{$i});
  }

  .text-info-#{$i} {
    color: var(--info-#{$i});
  }

  .border-primary-#{$i} {
    border-color: var(--primary-#{$i}) !important;
  }

  .border-grey-#{$i} {
    border-color: var(--grey-#{$i}) !important;
  }

  .border-error-#{$i} {
    border-color: var(--error-#{$i}) !important;
  }

  .border-warning-#{$i} {
    border-color: var(--warning-#{$i}) !important;
  }

  .border-success-#{$i} {
    border-color: var(--success-#{$i}) !important;
  }

  .border-info-#{$i} {
    border-color: var(--info-#{$i}) !important;
  }
}

// Dynamic Font Sizes
@for $i from 0 through 100 {
  .fs-#{$i} {
    font-size: #{$i}px !important;
  }
}

// Dynamic Font Weights
@for $i from 100 through 900 {
  .fw-#{$i} {
    font-weight: #{$i} !important;
  }
}

// Dynamic Opacity
@for $i from 0 through 100 {
  .opacity-#{$i} {
    opacity: #{$i / 100};
  }
}

// Dynamic Positioning
@for $i from 0 through 150 {
  .top-#{$i} {
    top: #{$i}px;
  }

  .right-#{$i} {
    right: #{$i}px;
  }

  .bottom-#{$i} {
    bottom: #{$i}px;
  }

  .left-#{$i} {
    left: #{$i}px;
  }
}

//Dynamic Responsive Widths in Percentage
@for $i from 0 through 100 {
  .w-#{$i} {
    width: unquote("#{$i}%");
  }
}

//Dynamic Widths in px
@for $i from 0 through 2000 {
  .w-#{$i}-px {
    width: #{$i}px;
  }
}

//Dynamic Heights in px
@for $i from 0 through 2000 {
  .h-#{$i}-px {
    height: #{$i}px;
  }
}

//Dynamic Max Widths
@for $i from 0 through 2000 {
  .max-w-#{$i} {
    max-width: #{$i}px;
  }
}

//Dynamic Border Radius
@for $i from 0 through 200 {
  .br-#{$i} {
    border-radius: #{$i}px !important;
    -webkit-border-radius: #{$i}px !important;
    -moz-border-radius: #{$i}px !important;
    -ms-border-radius: #{$i}px !important;
    -o-border-radius: #{$i}px !important;
  }
}

//Dynamic Padding PX
@for $i from 0 through 200 {
  .p-#{$i}-px {
    padding: #{$i}px !important;
  }
}

//Dynamic Margin Bottom PX
@for $i from 0 through 500 {
  .mb-#{$i}-px {
    margin-bottom: #{$i}px !important;
  }
}

//Dynamic Margin Top PX
@for $i from 0 through 500 {
  .mt-#{$i}-px {
    margin-top: #{$i}px !important;
  }
}

//Dynamic Transitions
@for $i from 0 through 10 {
  $decimal: $i / 10;
  .transition-#{$i} {
    transition: #{$decimal}s all linear;
    -webkit-transition: #{$decimal}s all linear;
    -moz-transition: #{$decimal}s all linear;
    -ms-transition: #{$decimal}s all linear;
    -o-transition: #{$decimal}s all linear;
  }
}
