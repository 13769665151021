.privacy-container {
  max-width: 90%;
  margin: 0px auto;
  padding-top: 20px;
  .headings {
    font-size: 20px;
    font-weight: 800;
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .sub-headings {
    font-size: 15px;
    font-weight: 700;
    margin: 5px 0;
  }
  .content {
    font-size: 14px;
    line-height: 1.5;
    padding: 5px 0;
  }
}
