@use "../variables.scss" as *;

.settings-content-wrapper {
  margin-top: 40px;
  margin-bottom: 80px;
  padding: 20px 40px;
  background-color: var(--white);
  border-radius: 8px;
  min-height: 752px;

  .settings-content {
    padding: 12px 0;
  }

  .security-item {
    max-width: 646px;
    margin-top: 30px;
    cursor: pointer;
    padding: 30px;
    border-radius: 16px;

    &:hover {
      background-color: #f0f0f0;
    }
  }
}

@media screen and (max-width: 768px) {
  .settings-content-wrapper {
    padding-inline: 0;
    margin-top: 0;
    background-color: transparent;
    .settings-content {
      padding: 0;
    }
  }
}
