@use "../variables.scss" as *;

.exporter-dashboard {
  height: fit-content;
  background-color: var(--grey-50);

  .dashboard-statistics {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    min-height: 174px;
    border-radius: 8px;
    border: 1px solid var(--grey-100);
    padding: 24px 0;
  }

  .statistic {
    width: 25%;
    min-width: 130px;
    padding-inline: 45px;
    padding-block: 30px;
    border-right: 1px solid var(--grey-100);
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
  }
  .statistic:nth-child(4) {
    border: none;
  }
}

.heading-wrapper {
  display: flex;
  flex-direction: row;
  gap: 32px;
  width: fit-content;
  padding: 0;

  .heading {
    white-space: nowrap;
    cursor: pointer;
    padding: 10px;
    transition: all 0.3s;
    user-select: none;
    text-align: center;

    &:hover {
      background-color: #f0f0f0;
    }

    &.active {
      font-weight: 600;
      border-bottom: 2px solid var(--primary-600);
    }
  }
}

.dashboard-wrapper {
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.draftcampaign-item {
  border-radius: 8px;
  padding: 22px;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 2fr;
  gap: 20px;

  .campaign-subitem {
    // max-width: 30%;
    width: 94%;
  }

  .loading-container {
    // width: fit-content;
    // max-width: 25%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .loading-bar {
      height: 8px;
      width: 100%;
      background-color: var(--grey-100);
      border-radius: 8px;

      .loading {
        height: 100%;
        border-radius: 8px;
      }
    }
  }
}

.campaign-item {
  border-radius: 8px;
  padding: 22px;
  display: grid;
  grid-template-columns: 3fr 3fr 1fr 2fr;
  gap: 46px;

  .campaign-subitem {
    // max-width: 30%;
    width: 94%;
  }

  .loading-container {
    // width: fit-content;
    // max-width: 25%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .loading-bar {
      height: 8px;
      width: 100%;
      background-color: var(--grey-100);
      border-radius: 8px;

      .loading {
        height: 100%;
        border-radius: 8px;
      }
    }
  }
}

.discover-slider-menu {
  position: sticky;
  top: 80px;
  background-color: var(--grey-50);
  z-index: 9;
}

@media (max-width: 768px) {
  .dashboard-statistics {
    justify-content: center;
    gap: 0;
    min-height: 0;
    .statistic {
      width: 50%;
      border: none !important;
      padding-inline: 0;
      gap: 16px;
      padding-block: 10px;
      padding-inline: 20px;
      h3 {
        width: 100%;
        text-align: left;
        font-size: 24px;
      }
    }
  }
  .heading-wrapper {
    gap: 20px;
  }
  .campaign-item {
    padding: 25px 20px;
    .campaign-subitem {
      max-width: 100%;
      width: 100%;
    }
  }
}
