.new-campaign {
  position: relative;

  .new-campaign-container {
    .new-campaign-content {
      position: sticky;
      top: 169px;
      left: 0px;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .new-campaign-sidebar {
      width: 100%;
      height: 100%;
      position: sticky;
      top: 169px;
      left: 0px;
    }
  }
}

.review {
  .review-border {
    border-radius: 8px;
    border: 1px solid var(--grey-100);
  }
  .review-text > p {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  .review-link {
    text-decoration: underline;
    color: var(--primary-600);
  }
}
