input[type="radio"] {
  display: none;
}

input[type="radio"] + span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid var(--grey-300);
  position: relative;
}

input[type="radio"] + span::after {
  content: "";
  display: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #008000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
input[type="radio"][data-checked] {
  border: 1px solid var(--primary-600);
}

input[type="radio"]:checked + span::after {
  display: block;
}
