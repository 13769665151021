// .sidebar {
//   width: 240px;
//   height: 100%;
//   //   padding: 30px 0px;
//   position: fixed;
//   top: 80px;
//   left: 0px;
//   z-index: 100;
//   overflow-y: auto;
// }

// .right-dashboard {
//   padding: 80px 40px 50px;
//   position: relative;
//   left: 240px;
//   width: calc(100% - 240px);
// }

.cube{
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: rotate(45deg) translate(-50%, -25%);
    border: 1px solid #101828;
    z-index: 10;
}

.spinner {
    animation: spin 1.3s linear infinite;
}
  
@keyframes spin {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}