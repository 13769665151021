@use "../variables.scss" as *;

.bg-grey\/25 {
  background: var(--grey-25);
}

.auth-form-wrapper {
  border-radius: 16px;
  border: 1px solid var(--grey-100, #e1e1e1);
  background: #fff;
  display: flex;
  padding: 46px 48px;
  flex-direction: column;
  align-items: flex-start;

  .auth-form-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 10px;

    .auth-form {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
